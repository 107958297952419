.accordion-demo .accordion-custom i,
.accordion-demo .accordion-custom span {
  vertical-align: middle;
}

.accordion-demo .accordion-custom span {
  margin: 0 0.5rem;
}

.accordion-demo .p-accordion p {
  line-height: 1.5;
  margin: 0;
}

ol > li::marker {
  font-weight: bold;
}

.p-accordion-header-text {
  width: 100%;
}

.faq-content h1 {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  margin: 0;
}

.faq-content h2 {
  font-size: 1.25rem !important;
  margin: 0;
}

.faq-content ul {
  list-style: disc !important;
  padding-left: 2rem !important;
}

.faq-content ol {
  list-style: decimal !important;
  padding-left: 2rem !important;
}

li {
  font-size: 1rem;
}

.ql-editor p {
  font-size: 1rem;
}
